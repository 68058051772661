.recommendedPost{
  padding-bottom: 5px;
  padding-top: 9px;
  margin: 15px auto 0 10px;
  border-top: 2px dashed black;
  border-bottom: 2px dashed black;
  transition: 0.2s border-color ease-in;
  color: black;
  .others{
    font-size: 25px;
    border-left: 3px solid rgba(0, 0, 0, 0.685);
    padding: 2px 0 2px 5px;
    transition: 0.2s border-color ease-in,
                0.2s color ease-in;

  }
  .sideTile{
    margin-top: 10px;
    margin-bottom: 15px;
  }
}
@media (max-width: 991.98px) { 
  .recommendedPost{
    margin: 0 auto 0 auto;
    max-width: initial;
    .container, .container-fluid{
      padding: 0px !important;
    }
  }
}