.contact{
  width: 100%;
  padding: 50px 10px 10px 10px;
  font-size: 20px;
  max-width: 600px;
  margin: 0 auto 0 auto;
  
  transition: color 0.2s ease-in;
  .info{
    width: 100%;
    padding: 5px;
    .infoDesc{
      text-align: center;
      padding: 3px 10px 3px 10px;
    }
    .mailInfo{
      border-left: 3px solid black;
      padding: 2px 2px 2px 8px;
      width: 150px;
      margin: 5px auto 5px auto;

      transition: border-color 0.2s ease-in;
    }
    .fbInfo{
      font-size: 100px;
      text-align: center;
      a{
        color: #151515;
        &:hover{
          color: #3b5998;
        }
      }
    }

    &:first-child{
      border-bottom: 1px dashed white;
    }
  }
  .sent{
    .thanks{
      z-index: 15 !important;
      opacity: 1 !important;
    }
    .form{
      opacity: 0 !important;
    }
  }
  .form{
    margin-top: 20px;
    box-shadow: 0 0 11px -2px rgba(0, 0, 0, 0.5);
    padding: 18px 10px 10px 18px;
    text-align: center;
    background-color: white;
    transition: background-color 0.2s ease-in;
    position: relative;
    .thanks{
      position: absolute;
      z-index: 9;
      left: 0px; right: 0px;
      top: 0px; bottom: 0px;
      margin: auto;
      background-color: white;
      width: 100%;
      height: 100%;
      user-select: none;
      opacity: 0;
      font-size: 45px;
      color: #099ef5;
      display: flex;
      justify-content: center;
      flex-direction: column;
      transition: opacity 0.2s ease-in,
                  background-color 0.2s ease-in;
    }
    form{
      position: relative;
      z-index: 11;
      max-width: 400px;
      margin: 0 auto 0 auto;
      opacity: 1;
      transition: opacity 0.2s ease-in;
      .showError{
        display: block !important;
      }
      .erros{
        display: none;
        font-weight: bold;
        color: #a50000;
        margin-bottom: 10px;
      }
    }
    textarea{
      width: 100%;
      border-radius: 3px;
    }
    input{
      margin: 0 0 15px 0;
      width: 100%;
      text-align: center;
      outline: none;
      border-radius: 3px;
    }
    .inputfield{
      textarea{
        border: 1px solid #000000ab;
      }
      input{
        border: 1px solid #000000ab;
      }
    }
    .submitfield{
      margin-top: 10px;
      input{
        border: 2px solid #000000ab;
        background-color: #fff;

        transition: background-color 0.2s ease-in,
                    color 0.2s ease-in;
        &:hover, &:focus{
          color: white;
          background-color: #000;
        }
      }
      input[disabled] {
        background-color: grey;
        color: white;
        border-color: grey;
      }
    }
  }
}