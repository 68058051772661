.article{
  width: 100%;
  padding: 30px 10px 10px 10px;
  margin: 0 auto 0 auto;
  .container, .container-fluid{
    padding: 0px !important;
    max-width: 1450px;
    margin: 0 auto 0 auto;

    .additionalInfo{
      opacity: 0.8;
      transition: 0.2s color ease-in;
    }
    .mainContent{
      padding: 0px !important;
      transition: 0.2s color ease-in;
    }
    .indexMenuContainer{
      padding: 3px;
      .additionalInfo{
        padding-right: 8px;
        text-align: right;
      }
    	// font-family: 'Ubuntu Mono', monospace;
    }
  }
}