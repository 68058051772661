.sideMenu{
  position: fixed;
  height: 100%;
  color: black;
  padding: 5px 15px 0 15px;
  width: 100%;
  z-index: 100;
  overflow: hidden;
  background-color: white;

  display: flex;
  justify-content: center;
  flex-direction: column;

  transform: translateX(-110%);
  transition: background-color 0.2s ease-in,
              transform 0.2s ease-in;

  .closeMenu{
    top: 0px;
    position: absolute;
    width: 50px;
    font-size: 40px;
    cursor: pointer;
  }
  .buttonsWrapper{
    padding: 0 5px 0 5px;

    a, a:hover, a:visited{
      text-decoration: none;
    }
    .simpleButton, .simpleButton-inverted{
      margin: 10px 0 10px 0px;
    }
  }
  .sideMenuSpacer{
    width: 100%;
  }
}