body {
  margin: 0;
  overflow-x: hidden;
  overflow-y: scroll;

  // &::after{
  //   content: "";
  //   position: absolute;
  //   left: 0px; right: 0px;
  //   top: 0px; bottom: 0px;
  //   margin: auto;
  //   width: 5px;
  //   background-color: red;
  //   height: 100vh;
  // }
}

