.postList{
  max-width: 1500px;
  margin: 0 auto 0 auto;
  .container, .container-fluid{
    .sectionTitle{
      margin-left: 10px;
      h3{
        margin-bottom: 0px;
      }
    }
    .postTile{
      padding: 10px !important;
    }
  }
}