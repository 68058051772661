.articleView{
  width: 100%;
  font-size: 50px;
  padding: 0 10px 10px 10px;
  border-left: 2px solid rgba(0, 0, 0, 0.336);
  border-right: 2px solid rgba(0, 0, 0, 0.336);

  transition: border-color 0.2s ease-in;

  img{
    width: 100% !important;
    height: initial !important;
    margin: 0 auto 0 auto;
    display: block;

    border-radius: 5px;
    border: 1px solid black;
    box-shadow: 0 0 11px -2px rgba(0,0,0,0.5);
  }
  a{
    position: relative;
    z-index: 9;
    &::after{
      content: "";
      position: absolute;
      width: calc(100% + 6px);
      height: 100%;
      background-color: #099ff5cc;
      left: -3px;
      bottom: -1px;
      transform-origin: bottom;
      transform: scaleY(0.1);
      transition: transform 0.2s ease-in;
      z-index: -1;
    }
    &:hover{
      color: white;
      text-decoration: none;
      &::after{
        transform: scaleY(1);
      }
    }
  }
  .itemDescription{
    transform: translateY(-4px);
    line-height: 0px;
    padding-bottom: 5px;
    text-decoration: underline;
  }
  .gist{
    a{
      color: black !important;
    }
  }
  .titleContainer{
    width: 100%;
    box-shadow: 0 0 9px -1px rgba(0, 0, 0, 0.8);
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.4);
    background-color: rgba(0, 0, 0, 0.4);
    .titleBackground{
      width: 100%;
      min-height: 250px;
      background-position: center;
      background-size: cover;
      display: flex;
      justify-content: center;
      flex-direction: column;
      font-size: 1em
    }
    .title{
      padding: 10px;
      color: white;
      text-align: center;
      text-shadow: -0px -0px 2px #000000, 0px 0px 3px #000000, 0px 0px 3px #000000;
    }
    .introContainer{
      font-size: 0.45em;
      padding: 10px;
      background-color: #0099ff;
      color: white;
    }
  }
  .contentContainer{
    font-size: 0.4em;
    padding: 12px;
    box-shadow: 0 0 4px -1px rgba(0,0,0,0.8);
    border-radius: 4px;
    margin-top: 20px;
    background-color: #fff;

    transition: background-color 0.2s ease-in;
  }

}



@media (max-width: 991.98px) { 
  .articleView{
    border: none;
    padding: 0 0 10px 0;
  }
}
// @media (max-width: 767.98px) { 
// .articleView{
//   font-size: 29px;
//   .title{
//     font-size: 1.1em;
//   }
//   .content{
//     padding: 8px;
//   }
// }
// }