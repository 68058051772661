.quote{
  width: 100%;
  font-family: 'Oswald', sans-serif;
  padding: 15px;
  font-size: 65px;
  .quoteContainer{
    position: relative;
    margin: 0 auto 0 auto;
    max-width: 750px;
    color: black;
    width: 100%;
    padding: 2px 0 2px 15px;
    transition: color 0.2s ease-in;
    .quoteBorder{
      position: absolute;
      background-color: #000;
      width: 7px;
      height: 100%;
      left: -2px;
      transform: scaleY(0);
      transition: background-color 0.2s ease-in;

    }

    .specialText{
      font-weight: bold;
      text-decoration: underline;
      transition: color 0.2s ease-in;
    }
  }
}
@media (max-width: 1499.98px) {
  .quote{
    font-size: 60px;
  }
}
@media (max-width: 1399.98px) {
  .quote{
    font-size: 52px;
  }
}
@media (max-width: 1249.98px) {
  .quote{
   font-size: 45px;
  }
}
@media (max-width: 1149.98px) {
  .quote{
   font-size: 40px;
  }
}
@media (max-width: 1049.98px) {
  .quote{
   font-size: 37px;
  }
}

