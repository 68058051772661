.footer{
  position: absolute;
  bottom: 0px;
  padding: 8px;

  width: 100%;
  background-color: rgb(235, 235, 235);

  transition: background-color 0.2s ease-in,
              color 0.2s ease-in;
  .copy{
    text-align: center;
    font-size: 19px;

  }
  .footerContainer{
    width: 100%;
    display: flex;;
    justify-content: center;
    font-size: 15px;
    .description{
      padding: 10px;
      border-left: 2px solid rgba(0, 0, 0, 0.7);
      border-right: 2px solid rgba(0, 0, 0, 0.7);
      .thanks{
        font-size: 18px;
        text-align: center;
      }
    }
    .navigation{
      display: flex;
      justify-content: space-around;
      text-align: center;
      border-right: 2px solid rgba(0, 0, 0, 0.7);
      .row{
        display: flex;
        justify-content: center;
        flex-direction: column;        
      }
    }
  }
  .description, .navigation{
    width: 50%;
    max-width: 320px;
    transition: border-color 0.2s ease-in;
  }
  .footerNavItem{
    cursor: pointer;
    opacity: 0.7;
    padding: 1px 3px 1px 3px;
    margin: 1px 0 1px 0;
    border-left: 2px solid black;
    border-right: 2px solid black;
    
    border-color: transparent;

    transition: opacity 0.2s ease-in,
                border-color 0.2s ease-in;
    a{
      display: block;
      width: 100%;
      height: 100%;
      &:hover{
        text-decoration: none;
      }
    }
    &:hover{
      opacity: 1;
      border-color: rgba(0, 0, 0, 0.75);
    }
  }
}