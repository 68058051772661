.categorySelect{
  font-family: 'Ubuntu Mono', monospace;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 25px;
  .categorySelectItem{
    position: relative;
    text-align: center;
    font-size: 25px;
    letter-spacing: 1px;
    text-align: center;
    border-right: 2px solid black;
    cursor: pointer;

    transition: border-color 0.2s ease-in;
    a, a:hover, a:visited{
      padding: 2px 15px 2px 15px;
      display: block;
      height: 100%;
      width: 100%;
      color: black;
      text-decoration: none !important;
    }
    &:first-child{
      border-left: 2px solid black;
    }
    &::after{
      content: "";
      position: absolute;
      margin: 0 auto 0 auto;
      left: 0px; right: 0px;
      bottom: 0px;
      width: 80%;
      background-color: black;
      height: 2px;
      opacity: 0;
      transform: scaleX(0);
      transition: transform 0.2s ease-in,
                  opacity 0.2s ease-in,
                  background-color 0.2s ease-in;
    }
    &:hover{
      &::after{
        transform: scaleX(0.65);
        opacity: 1;
      }
    }
  } 
  .selectedCategory{
    cursor: default;
    &::after{
      transform: scaleX(1) !important;
      opacity: 1;
    }
  }

}