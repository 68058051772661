.new{
  width: 100%;
  padding: 30px 10px 10px 10px;
  .postList{
    .container, .container-fluid{
      .sectionTitle{
        display: none;
      }
    }
  }
}

@media (max-width: 991.98px) { 
  .new{
    padding: 10px 5px 5px 5px;
  }
}
@media (max-width: 767.98px) { 
  .new{
    padding: 6px;
  }
}