.post{
  width: 100%;
  height: 100%;
  margin: 0 auto 0 auto;
  background-color: #fff;
  box-shadow: 0 0 11px -2px rgba(0, 0, 0, 0.459);
  border-radius: 4px;
  overflow: hidden;

  transition: color 0.2s ease-in;
  .postImage{
    width: 100%;
    height: 250px;
    background-size: cover;
    position: relative;
    background-position: center;
    .postImageContainer{
      position: absolute;
      left: 0px; top: 0px;
      width: 100%;
      height: 100%;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .postDate{
      position: absolute;
      bottom: 0px;
      right: 0px;
      padding: 5px 10px 5px 6px;
      background-color: rgba(0, 0, 0, 0.616);
      border-top-left-radius: 3px;
      color: white;
    }
  }
  .postDescription{
    padding: 10px;
    font-size: 25px;
    .postTitle{
      padding: 2px;
      font-size: 1.1em;
      border-bottom: 1px solid black;
    }
    .postIntro{
      padding: 2px;
      font-size: 0.7em;
    }
    .readMore{
      font-size: 0.7em;
      width: 100%;
      padding-right: 20px;
      text-align: right;
      cursor: pointer;
    }
  }
}