.cookiesPopUp{
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100vw;
  font-size: 16px;
  padding-top: 5px;
  padding-right: 12px;
  padding-bottom: 5px;
  background-color: rgb(49, 49, 49);
  z-index: 101;
  .cokkiesInfo{
    padding: 5px;
    text-align: center;
    color: white;
  }
  .cookiesOptions{
    display: flex;
    justify-content: center;
    font-size: 15px;
    .cookieCancel, .cookieOk{
      padding: 3px;
      cursor: pointer;
    }
    .cookieCancel{
      color: white;
      a{
        color: rgb(182, 182, 182);
        &:hover{
          color: white;
        }
      }
    }
    .cookieOk{
      margin-left: 8px;
      background-color: rgb(177, 177, 177);
      border-radius: 3px;
      padding: 3px 10px 3px 10px;
      transition: background-color 0.2s ease-in;

      &:hover{
        background-color: white;
      }
    }
  }
}