.indexMenu{
  transition: color 0.2s ease-in;
  position: fixed;
  width: 230px;
  .indexItems{
    width: 100%;
    padding-top: 40px;
    font-size: 17px;
    .indexTitle{
      width: 90px;
      padding: 4px;
      text-align: center;
      font-size: 20px;
      margin: 0 auto 9px auto;
      border: 2px solid rgba(0, 0, 0, 0.568);
      border-style: none solid none solid;

      transition: border-color 0.2s ease-in;

    }
    .indexItem{
      margin-bottom: 4px;
      padding: 2px;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
      opacity: 0.7;

      transition: border-color 0.2s ease-in;

      cursor: pointer;
      transition: opacity 0.2s ease-in;
      &:hover{
        opacity: 1;
      }
    }
  }
}
@media (max-width: 1450px) { 
  .indexMenu{
    width: 225px;
  }
}
@media (max-width: 1390px) { 
  .indexMenu{
    width: 190px;
  }
}
@media (max-width: 1199.98px) { 
  .indexMenu{
    width: 235px;
  }
}